export function getDomainUrl(request: Request) {
  const host = request.headers.get('X-Forwarded-Host') ?? request.headers.get('host') ?? new URL(request.url).host;
  const protocol = host.includes('localhost') ? 'http' : 'https';
  return `${protocol}://${host}`;
}

export const getDurationFromTimestamp = (timestamp: number) => Date.now() - timestamp;

export const roundNumber = (value: number, fractionDigits = 2) => {
  const factor = Math.pow(10, fractionDigits);
  return Math.round((value + Number.EPSILON) * factor) / factor;
};
